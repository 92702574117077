.App {
  background-color: #282c34;
  text-align: center;
  display: flex;
  color: white;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
  font-family: sans-serif;
}

.sidemenu {
  width: 260px;
  padding: 10px;
  background-color: #111B37;
  position: relative;
}

.sidemenu-logo {
  padding-bottom: 12px;
}

.side-menu-button {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: left;
  transition: ease 0.25s all;
}

.side-menu-button span {
  padding-left: 6px;
  padding-right: 12px;
}

.side-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.chatbox {
  flex: 1;
  border: 1px solid #ccc;
  position: relative;
  background-color: white;
  color: grey;
  font-weight: bold;
  overflow: hidden;
}

.chatbox-header-text-box {
  padding: 12px;
  color: gray;
  border-radius: 5px;
  border: none;
  outline: none;
  margin: 15px;
  font-weight: normal;
}

.chatbox-header-text-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: black;
  padding-left: 10px;
}

.ama {
  background-color: #D6F2F6;
}

.visual {
  background-color: #D9F0DC;
}
.voice {
  background-color: #FDE5CC;
}
.upload {
  background-color: #D4DAEA;
}

.chat-log {
  text-align: left;
  overflow-y: scroll;
  height: 80%;
}

.chat-log-topbar-settingsicon {
  text-align: right;
}

.error {
  text-align: center;
  color: red;
}

.chat-log-topbar img {
  width: auto;
  padding-right: 20px;
  padding-top: 10px;
}


.chat-message.chatgpt {
  background-color: #D6F2F6;
}

.chat-message-center {
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding: 12px;
  padding-left: 24px;
  padding-right: 24px;
}

.avatar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.avatar.chatgpt {
  background: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}


.chatbox-input-holder {
  padding: 24px;
  position: absolute;
  bottom: 0;
  left: 0; right: 0;

}

.chatbox-input-holder form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbox-input-textarea {
  width: 70%;
  padding: 12px;
  color: gray;
  border-radius: 5px;
  border: none;
  outline: none;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  font-size: 1.25em;
}

.chatbox-input-button {
  background-color: white;
  border: none;
  outline: none;
  padding-right: 40px;
}

.chatbox-header {
  padding: 24px;
  position: absolute;
  top: 175px;
  left: 0; right: 0;
  align-items: center;
  justify-content: center;
}

.chatbox-header-title {
  width: 80% ;
  padding: 12px;
  align-items: center;
  justify-content: center;
  display: inline-block;
}

.chatbox-header-common {
  padding-right: 20px;
}


.chatbox-header.chatbox-header-invisible {
 visibility: hidden;
}

.aida-logo-div {
  max-width: 360px;
  align-items: center;
  justify-content: center;
  display: inherit;
  padding: 12px;
}

.chatbox-header-text {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.aida-logo img {
  width: 100%;
  height: auto;
}

.message {
  padding-left: 40px;
  padding-right: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: initial;

  position: absolute;
  bottom: 15px;
  left: 30px;
}

.loader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: initial;
}

.dot1,
.dot2,
.dot3 {
  background: #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 5px;
}

.dot1 {
  background: #62d7fa;
}
.dot2 {
  background: #53a953;
}
.dot3 {
  background: #f34141;
}

.dot1.animate {
  animation: jump 1.6s -0.32s linear infinite;
}
.dot2.animate {
  animation: jump 1.6s -0.16s linear infinite;
}
.dot3.animate {
  animation: jump 1.6s linear infinite;
}

@keyframes jump {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}